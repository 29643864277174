import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header className="header">
          <div className="inner">
            <div className="row header-top">
              <div className="four offset-by-six columns">
                <nav>
                  <ol className="uilist-hor nav-top">
                    <li className="first">
                      <a href="/fr/contact/">Contact</a>
                    </li>
                    <li>
                      <a href="/fr/lexique/">Lexique</a>
                    </li>
                    <li className="last">
                      <a href="/fr/faq-questions-diarrhee/">FAQ</a>
                    </li>
                  </ol>
                  <ul className="uilist-hor nav-top nav-lang">
                    <li id="uilist-hor nav-top nav-lang_nl-BE">
                      <a href="/feiten-over-diarree/buikgriep/">NL</a>
                    </li>
                    <li id="uilist-hor nav-top nav-lang_fr" className="active">
                      <a href="/fr/la-diarrhee/infection-virale-ou-bacterienne/">
                        FR
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="two columns">
                <form
                  className="search"
                  method="post"
                  action="/"
                >
                  <div className="hiddenFields">
                    <input
                      type="hidden"
                      name="XID"
                      defaultValue="d3195d40b6be50d3b3d60a8f839e36ecce48ba0a"
                    />
                    <input type="hidden" name="ACT" defaultValue="45" />
                    <input
                      type="hidden"
                      name="result_page"
                      defaultValue="/fr/chercher"
                    />
                    <input
                      type="hidden"
                      name="collection"
                      defaultValue="pages"
                    />
                    <input
                      type="hidden"
                      name="search_mode"
                      defaultValue="all"
                    />
                    <input type="hidden" name="site_id" defaultValue="1" />
                  </div>
                  <div className="row">
                    <div className="twelve columns">
                      
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="row header-bottom">
              <div className="two columns">
                <span className="logo">
                  <a href="/fr/">Imodium®</a>
                </span>
              </div>
              <div className="ten columns">
                <nav className="top-bar">
                  <ul className="nav-info">
                    <li className="name">
                      <h1>
                        <a href="#">Menu</a>
                      </h1>
                    </li>
                    <li className="toggle-topbar">
                      <a href="#" />
                    </li>
                  </ul>
                  <section>
                    <ol className="uilist-hor nav-main">
                      <li className="first" id="nav-sub-4">
                        <a href="/fr/comment-agit-imodium/">
                          Comment agit
                          <br />
                          IMODIUM®?
                        </a>
                      </li>
                      <li id="nav-sub-5">
                        <a href="/fr/medicaments-imodium/">
                          Médicaments <br />
                          IMODIUM®
                        </a>
                      </li>
                      <li className="parent-active" id="nav-sub-6">
                        <a href="/fr/la-diarrhee/">
                          Diarrhée – causes
                          <br />
                          et traitement
                        </a>
                      </li>
                      <li id="nav-sub-8">
                        <a href="/fr/diarrhee-en-voyage/">
                          Diarrhée
                          <br />
                          en voyage
                        </a>
                      </li>
                      <li id="nav-sub-9">
                        <a href="/fr/diarrhee-chez-les-enfants/">
                          Diarrhée chez
                          <br />
                          les enfants
                        </a>
                      </li>
                      <li className="last" id="nav-sub-10">
                        <a href="/fr/materiel-dinformation-diarrhee/">
                          Matériel
                          <br />
                          d'information
                        </a>
                      </li>
                    </ol>
                  </section>
                </nav>
                <script
                  type="text/javascript"
                  dangerouslySetInnerHTML={{
                    __html:
                      '\nvar item = document.getElementById("nav-sub-10").getElementsByTagName("a");\nvar span = document.createElement("span");\nspan.className = "menu-info";\nspan.innerHTML = " ";\nitem[0].appendChild(span);\n'
                  }}
                />
              </div>
            </div>
          </div>
        </header>
        <div className="main row">
          <div className="sidebar three columns">
            <ul className="side-nav">
              <li className="first overview">
                <a href="/fr/la-diarrhee/"> En savoir plus sur la diarrhée </a>
              </li>
              <li>
                <a href="/fr/la-diarrhee/conseils-pour-le-traitement-des-symptomes-de-la-diarrhee/">
                  Conseils pour le traitement des symptomes de la diarrhée
                </a>
              </li>
              <li>
                <a href="/fr/la-diarrhee/causes-de-la-diarrhee/">
                  Causes de la diarrhée
                </a>
              </li>
              <li>
                <a href="/fr/la-diarrhee/stress-et-diarrhee/">
                  Stress et diarrhée
                </a>
                <ul>
                  <li className="first">
                    <a href="/fr/la-diarrhee/stress-et-diarrhee/emploi-du-temps-charge/">
                      Emploi du temps chargé
                    </a>
                  </li>
                  <li className="last">
                    <a href="/fr/la-diarrhee/stress-et-diarrhee/diarrhee-examens/">
                      Faire face aux examens
                    </a>
                  </li>
                </ul>
              </li>
              <li className="active">
                <a href="/fr/la-diarrhee/infection-virale-ou-bacterienne/">
                  Infection virale ou bactérienne
                </a>
              </li>
              <li>
                <a href="/fr/la-diarrhee/norovirus/">Norovirus</a>
              </li>
              <li>
                <a href="/fr/la-diarrhee/la-diarrhee-pendant-les-regles/">
                  La diarrhée pendant les règles
                </a>
              </li>
              <li>
                <a href="/fr/la-diarrhee/la-diarrhee-chronique/">
                  La diarrhée chronique
                </a>
              </li>
              <li>
                <a href="/fr/la-diarrhee/consultez-votre-medecin/">
                  Consulter un médecin?
                </a>
              </li>
              <li className="last">
                <a href="/fr/la-diarrhee/des-habitudes-alimentaires-saines/">
                  Des habitudes alimentaires saines
                </a>
                <ul>
                  <li className="first">
                    <a href="/fr/la-diarrhee/des-habitudes-alimentaires-saines/allergies-alimentaires/">
                      Allergies alimentaires{" "}
                    </a>
                  </li>
                  <li>
                    <a href="/fr/la-diarrhee/des-habitudes-alimentaires-saines/intoxication-alimentaire/">
                      Intoxication alimentaire
                    </a>
                  </li>
                  <li className="last">
                    <a href="/fr/la-diarrhee/des-habitudes-alimentaires-saines/recettes-sante/">
                      Recettes Santé
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="page nine columns page-28">
            <h1>Infection virale ou bactérienne</h1>
            <div className="img">
              <img
                src="/assets/files/pages/buikgriep.jpeg"
                width="701"
                height="289"
                alt="Infection virale ou bactérienne"
              />
            </div>
            <div className="intro row">
              <div className="three columns">
                <h2>Les infections à l’origine de la diarrhée</h2>
              </div>
              <div className="nine columns">
                <p>
                  La <strong>diarrhée</strong>, souvent associée à des nausées
                  ou des vomissements, des maux de tête et une fièvre légère,
                  est un des symptômes courants d'une infection de l'estomac et
                  des intestins, mieux connue sous le nom de{" "}
                  <strong>gastro-entérite</strong> bactérienne ou virale.
                </p>
                <p>
                  Dans la plupart des cas, vous irez mieux en quelques jours,
                  surtout si l'infection est d'origine virale. Certains groupes
                  vulnérables, dont les jeunes enfants, les malades et les
                  personnes âgées, auront besoin d'un avis médical. Vous devez
                  demander un avis médical si vous avez de la fièvre ou du sang
                  dans vos selles.
                </p>
              </div>
            </div>
            <h4>
              Les causes principales de la gastro-entérite bactérienne ou virale
            </h4>
            <ul>
              <li>
                <a href="/fr/la-diarrhee/des-habitudes-alimentaires-saines/intoxication-alimentaire/">
                  Intoxication alimentaire
                </a>
                &nbsp;ou consommation d'eau contaminée ( lors d'un voyage à
                l'étranger, elle est appelée{" "}
                <a href="/fr/diarrhee-en-voyage/">diarrhée du voyageur</a> ou{" "}
                <a href="/fr/diarrhee-en-voyage/">turista</a>).
              </li>
              <li>
                Infection par un norovirus ou d'autres virus (principalement en
                hiver).
              </li>
            </ul>
            <p>
              Malheureusement, une gastro-entérite peut être extrêmement
              contagieuse et donc si vous en souffrez, veillez à prendre un soin
              tout particulier pour vous laver les mains après être allé aux
              toilettes et avant de préparer de la nourriture pour d'autres
              personnes. Si vous le pouvez, restez chez vous autant que
              possible, jusqu'à ce que la diarrhée ou les vomissements aient
              cessé depuis au moins 48 heures.
            </p>
          </div>
        </div>
        <Footer></Footer>
        <div id="myModal" className="reveal-modal large">
          <style
            dangerouslySetInnerHTML={{
              __html:
                ".embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container video, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }"
            }}
          />
          <div className="embed-container">
            <video
              src="/assets/video/imodium-2018-fr.mp4"
              poster="posterimage.jpg"
            />
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="fr" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
        <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
        
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\nif($(\"body\").hasClass(\"nl-BE\")){cookiesDirective('bottom',5,'/cookiebeleid');}else{cookiesDirective('bottom',5,'/fr/politique-en-matiere-de-cookies');}\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
